export const getToiletDirectionUrl = ({
  longitude,
  latitude,
  fromLongitude,
  fromLatitude,
}) => {
  const from =
    fromLatitude && fromLongitude ? `${fromLatitude},${fromLongitude}` : "";
  const map = `#map=18/${latitude}/${longitude}`;
  return `https://www.openstreetmap.org/directions?engine=fossgis_osrm_foot&from=${from}&to=${latitude},${longitude}${map}`;
};
