// equatorial mean radius of Earth (in meters)
const R = 6378137;

/**
 *
 * @param {number} x
 */
function squared(x) {
  return x * x;
}

/**
 *
 * @param {number} x
 */
function toRad(x) {
  return (x * Math.PI) / 180.0;
}

/**
 *
 * @param {number} x
 */
function hav(x) {
  return squared(Math.sin(x / 2));
}

export const haversineDistance = (coords1, coords2) => {
  const aLat = toRad(coords1.latitude);
  const bLat = toRad(coords2.latitude);
  const aLng = toRad(coords1.longitude);
  const bLng = toRad(coords2.longitude);

  const ht =
    hav(bLat - aLat) + Math.cos(aLat) * Math.cos(bLat) * hav(bLng - aLng);
  return 2 * R * Math.asin(Math.sqrt(ht));
};
