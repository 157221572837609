import * as React from "react";

import { filterNearbyToilets } from "./filterNearbyToilets";

export const useFilterNearbyToilets = (preparedToilets, userCoordinates) => {
  return React.useMemo(() => {
    if (!userCoordinates) {
      return null;
    }
    return filterNearbyToilets(preparedToilets, userCoordinates);
  }, [preparedToilets, userCoordinates]);
};
