import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import * as classes from "./style.module.css";

export const Button = ({
  children,
  onClick,
  leftIcon,
  className,
  ...props
}) => {
  return (
    <button
      className={clsx(classes.root, className)}
      onClick={onClick}
      {...props}
    >
      {leftIcon && (
        <span className={classes.leftIconContainer}>{leftIcon}</span>
      )}
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  leftIcon: PropTypes.node,
  className: PropTypes.string,
};
