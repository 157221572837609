import * as React from "react";

export const useToiletMarkers = (
  toilets,
  { type = "icon", getContent } = {},
) => {
  const markers = React.useMemo(() => {
    if (!toilets || !toilets.length) {
      return null;
    }

    return toilets.map((toilet, index) => ({
      id: toilet.lavatoryID,
      longitude: toilet.longitude,
      latitude: toilet.latitude,
      popup: toilet.street,
      type,
      ...(getContent && { content: getContent(toilet, index) }),
    }));
  }, [toilets, type, getContent]);

  return markers;
};
