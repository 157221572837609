import * as React from "react";

import { Loader } from "../loader";

import * as classes from "./style.module.css";

export const InteractiveMapPlaceholder = () => {
  return (
    <div className={classes.placeholder}>
      <Loader />
    </div>
  );
};
