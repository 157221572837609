import * as React from "react";

export const useCurrentPosition = () => {
  const [coordinates, setCoordinates] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const getCurrentPosition = () => {
    setLoading(true);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;

        setCoordinates({ longitude, latitude });
        setError(null);
        setLoading(false);
      },
      (positionError) => {
        setCoordinates(null);
        setError(positionError.code);
        setLoading(false);
      },
      {
        enableHighAccuracy: false,
        maximumAge: 5000,
      },
    );
  };

  return [coordinates, error, loading, getCurrentPosition];
};
