// extracted by mini-css-extract-plugin
export var box = "index-module--box--a42b9";
export var boxesContainer = "index-module--boxesContainer--f0e5e";
export var boxesGrid = "index-module--boxesGrid--3c138";
export var boxesHeading = "index-module--boxesHeading--c7ea9";
export var cta = "index-module--cta--6bce7";
export var ctaBounce = "index-module--cta-bounce--ffd25";
export var ctaContainer = "index-module--ctaContainer--645f4";
export var ctaQuestion = "index-module--ctaQuestion--8aad6";
export var errorContainer = "index-module--errorContainer--71652";
export var loaderContainer = "index-module--loaderContainer--7c202";
export var resultsContainer = "index-module--resultsContainer--0acaa";
export var scrollCurtain = "index-module--scrollCurtain--7df3a";
export var toiletCount = "index-module--toiletCount--09ba2";
export var visible = "index-module--visible--630ee";