export const prepareToilet = (toilet) => {
  const longitude = Number.parseFloat(toilet.longitude);
  const latitude = Number.parseFloat(toilet.latitude);

  return {
    ...toilet,
    longitude,
    latitude,
  };
};
