// extracted by mini-css-extract-plugin
export var icon = "style-module--icon--62490";
export var listItem = "style-module--listItem--043a9";
export var listItemContainer = "style-module--listItemContainer--b734c";
export var listItemOrdered = "style-module--listItemOrdered--648ce";
export var loaded = "style-module--loaded--38835";
export var root = "style-module--root--b1ea2";
export var toiletAttributeContainer = "style-module--toiletAttributeContainer--48a78";
export var toiletAttributesContainer = "style-module--toiletAttributesContainer--56025";
export var toiletContainer = "style-module--toiletContainer--6d083";
export var toiletDirectionIcon = "style-module--toiletDirectionIcon--41e0c";
export var toiletHeader = "style-module--toiletHeader--a417a";
export var toiletName = "style-module--toiletName--1378a";