import * as React from "react";
import clsx from "clsx";

import * as classes from "./style.module.css";

export const MapContainer = ({ children, sticky = false, className }) => (
  <div className={clsx(classes.root, sticky && classes.sticky, className)}>
    {children}
  </div>
);
