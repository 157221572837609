import { haversineDistance } from "../geo/haversineDistance";

export const filterNearbyToilets = (toilets, userCoordinates) => {
  const toiletsWithDistance = toilets.map((toilet) => {
    const { longitude, latitude } = toilet;
    return {
      ...toilet,
      distance: haversineDistance(userCoordinates, { longitude, latitude }),
    };
  });

  const toiletsInPerimeter = toiletsWithDistance.filter(
    (toilet) => toilet.distance <= 1000,
  );

  const sortedToiletsByDistance = toiletsInPerimeter.sort(
    (toilet1, toilet2) => toilet1.distance - toilet2.distance,
  );

  return sortedToiletsByDistance.slice(0, 4);
};
