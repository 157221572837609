import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { getToiletDirectionUrl } from "../../geo/toiletDirectionUrl";

import WheelchairIcon from "../../images/wheelchair.svg";
import CreditCardIcon from "../../images/credit-card.svg";
import CoinsIcon from "../../images/coins.svg";
import EuroSignIcon from "../../images/euro-sign.svg";
import NoEuroIcon from "../../images/no-euro.svg";
import DropletIcon from "../../images/droplet.svg";
import TurnIcon from "../../images/diamond-turn.svg";

import * as classes from "./style.module.css";
import { usePirschClient } from "../../pirsch";

export const NearbyToiletsList = ({
  toilets,
  ordered = false,
  userCoordinates,
}) => {
  const ListTag = ordered ? "ol" : "ul";

  const listItems = React.useRef([]);
  const [listItemIntersections, setListItemIntersections] = React.useState({});

  const pirschClient = usePirschClient();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setListItemIntersections((previousState) => ({
          ...previousState,
          [entry.target.dataset.toiletId]: entry.isIntersecting,
        }));
      });
    });

    listItems.current.forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <ListTag className={clsx(classes.root)}>
      {toilets.map((toilet) => {
        const { latitude, longitude } = toilet;
        const isLoaded = !!listItemIntersections[toilet.lavatoryID];

        return (
          <li
            key={toilet.lavatoryID}
            className={clsx(
              classes.listItem,
              ordered && classes.listItemOrdered,
              isLoaded && classes.loaded,
            )}
            data-toilet-id={toilet.lavatoryID}
            ref={(element) => listItems.current.push(element)}
          >
            {isLoaded ? (
              <div className={classes.listItemContainer}>
                <div className={classes.toiletContainer}>
                  <div className={classes.toiletHeader}>
                    <div className={classes.toiletName}>{toilet.street}</div>
                    <a
                      href={getToiletDirectionUrl({
                        longitude,
                        latitude,
                        ...(userCoordinates && {
                          fromLatitude: userCoordinates.latitude,
                          fromLongitude: userCoordinates.longitude,
                        }),
                      })}
                      rel="external noopener noreferrer"
                      target="_blank"
                      title="Get directions"
                      onClick={() => {
                        pirschClient?.event(
                          "get toilet directions anchor clicked",
                        );
                      }}
                    >
                      <TurnIcon className={classes.toiletDirectionIcon} />
                    </a>
                  </div>

                  <div className={classes.toiletAttributesContainer}>
                    {!!toilet.isHandicappedAccessible && (
                      <ToiletAttribute icon={WheelchairIcon}>
                        Accessible
                      </ToiletAttribute>
                    )}
                    {!!toilet.price ? (
                      <>
                        <ToiletAttribute icon={EuroSignIcon} aria-label="price">
                          {toilet.price.toFixed(2).replace(".", ",")}
                        </ToiletAttribute>
                        {!!toilet.canBePayedWithNFC && (
                          <ToiletAttribute icon={CreditCardIcon}>
                            Contactless payment
                          </ToiletAttribute>
                        )}
                        {!!toilet.canBePayedWithCoins && (
                          <ToiletAttribute icon={CoinsIcon}>
                            Coin payment
                          </ToiletAttribute>
                        )}
                      </>
                    ) : (
                      <ToiletAttribute icon={NoEuroIcon}>Free</ToiletAttribute>
                    )}
                    {!!toilet.hasUrinal && (
                      <ToiletAttribute icon={DropletIcon}>
                        Urinal
                      </ToiletAttribute>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </li>
        );
      })}
    </ListTag>
  );
};

NearbyToiletsList.propTypes = {
  toilets: PropTypes.arrayOf(PropTypes.object).isRequired,
  ordered: PropTypes.bool,
};

const ToiletAttribute = ({ icon: Icon, children, ...props }) => {
  return (
    <div
      className={classes.toiletAttributeContainer}
      aria-label={props["aria-label"]}
    >
      <Icon className={classes.icon} aria-hidden />
      {children}
    </div>
  );
};

export default NearbyToiletsList;
