import * as React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";

import { Layout } from "../components/layout";
import { Seo } from "../components/seo";
import { MapContainer } from "../components/mapContainer";
import { InteractiveMapPlaceholder } from "../components/interactiveMap/placeholder";
import { NearbyToiletsList } from "../components/nearbyToiletsList";
import { Button } from "../components/button";
import { Loader } from "../components/loader";

import { usePreparedToilets } from "../toilet/usePreparedToilets";
import { useFilterNearbyToilets } from "../toilet/useFilterNearbyToilets";
import { useCurrentPosition } from "../geo/useCurrentPosition";
import { useIsBrowser } from "../hooks/useIsBrowser";
import { useToiletMarkers } from "../hooks/useToiletMarkers";

import toilets from "../data/toilets.json";

import PinIcon from "../images/pin.svg";

import * as classes from "./index.module.css";
import { usePirschClient } from "../pirsch";

const InteractiveMap = React.lazy(() => import("../components/interactiveMap"));

const getMarkerContent = (_, index) => index + 1;

const IndexPage = () => {
  const isBrowser = useIsBrowser();
  const resultsContainer = React.useRef();
  const scrollCurtain = React.useRef();
  const preparedToilets = usePreparedToilets(toilets);
  const pirschClient = usePirschClient();

  const [
    userCoordinates,
    userCoordinatesError,
    userCoordinatesLoading,
    getUserCoordinates,
  ] = useCurrentPosition();
  const nearbyToilets = useFilterNearbyToilets(
    preparedToilets,
    userCoordinates,
  );

  const markers = useToiletMarkers(nearbyToilets, {
    type: "number",
    getContent: getMarkerContent,
  });

  const onMapLoaded = React.useCallback(() => {
    if (resultsContainer.current) {
      window.setTimeout(() => {
        resultsContainer.current.scrollIntoView();
      }, 500);
    }
  }, []);

  const onMarkerClicked = React.useCallback((markerId) => {
    const item = document.querySelector(`[data-toilet-id=${markerId}]`);
  }, []);

  const hasNearbyToilets = nearbyToilets && nearbyToilets.length > 0;

  React.useEffect(() => {
    if (!nearbyToilets || !pirschClient) {
      return;
    }

    if (nearbyToilets.length === 0) {
      pirschClient.event("no nearby toilets found");
    }

    pirschClient.event("nearby toilets found", undefined, {
      count: nearbyToilets.length,
    });
  }, [nearbyToilets, pirschClient]);

  React.useEffect(() => {
    if (userCoordinatesError !== null) {
      pirschClient?.event("get user coordinates errored");
    }
  }, [userCoordinatesError, pirschClient]);

  React.useEffect(() => {
    if (scrollCurtain.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            entry.target.classList.remove(classes.visible);
          } else {
            entry.target.classList.add(classes.visible);
          }
        },
        { rootMargin: "-1px 0px 0px 0px", threshold: [1] },
      );

      observer.observe(scrollCurtain.current);

      return () => observer.disconnect();
    }
  }, [hasNearbyToilets, userCoordinates]);

  const {
    allToilet: { totalCount },
    allBorough: { nodes: boroughs },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      allToilet {
        totalCount
      }
      allBorough(sort: { fields: name }) {
        nodes {
          name
          toiletCount
        }
      }
    }
  `);

  return (
    <Layout isHome>
      <Seo isHome />
      {userCoordinatesLoading && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      {!userCoordinates && !userCoordinatesLoading && (
        <div className={classes.ctaContainer}>
          <h3 className={classes.ctaQuestion}>Is nature calling?</h3>
          <Button
            onClick={() => {
              getUserCoordinates();

              pirschClient?.event("find the nearest toilet button clicked");
            }}
            leftIcon={<PinIcon width="20px" />}
            className={classes.cta}
          >
            Find the nearest toilet
          </Button>
          <div className={classes.boxesContainer}>
            <div className={classes.boxesHeading}>
              <div className={classes.toiletCount}>{totalCount}</div>
              <div>
                <Link to="/toilets">Public Toilets in Berlin</Link>
              </div>
            </div>
            <details>
              <summary></summary>
              <div className={classes.boxesGrid}>
                {boroughs.map(({ name, toiletCount }) => (
                  <div key={name} className={classes.box}>
                    <div className={classes.toiletCount}>{toiletCount}</div>
                    <div>
                      <Link to={`/toilets/borough/${name}`}>{name}</Link>
                    </div>
                  </div>
                ))}
              </div>
            </details>
          </div>
        </div>
      )}

      {userCoordinates && (
        <>
          {hasNearbyToilets ? (
            <div ref={resultsContainer} className={classes.resultsContainer}>
              <div className={classes.scrollCurtain} ref={scrollCurtain} />
              {isBrowser && (
                <MapContainer sticky>
                  <React.Suspense fallback={<InteractiveMapPlaceholder />}>
                    <InteractiveMap
                      center={userCoordinates}
                      markers={markers}
                      onLoaded={onMapLoaded}
                      onMarkerClicked={onMarkerClicked}
                      showCenterMarker
                      isCenterUserCoordinates
                    />
                  </React.Suspense>
                </MapContainer>
              )}
              <NearbyToiletsList
                toilets={nearbyToilets}
                userCoordinates={userCoordinates}
                ordered
              />
            </div>
          ) : (
            <div className={classes.errorContainer}>
              <div>No toilets found nearby</div>
              <i>(Are you sure you're in Berlin?)</i>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};

export default IndexPage;
